
import {Component, Ref, Vue} from "vue-property-decorator";
import IlamBilgileriList from "@/components/takip/list/IlamBilgileriList.vue";
import TakipYoluList from "@/components/takip/list/TakipYoluList.vue";
import KiraBilgileriList from "@/components/takip/list/KiraBilgileriList.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import TakipForm from "@/components/forms/TakipForm.vue";
import AlacakList from "@/components/lists/AlacakList.vue";
import BorcluCardList from "@/components/lists/BorcluCardList.vue";
import AlacakliCardList from "@/components/lists/AlacakliCardList.vue";
import TakipEkAlanList from "@/components/lists/TakipEkAlanList.vue";
import TakipEkAlan from "@/components/forms/TakipEkAlanForm.vue";
import KapakHesabiInfo from "@/components/infos/KapakHesabiInfo.vue";
import DokumanList from "@/components/lists/DokumanList.vue";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import {BorcluEntity} from "@/entity/BorcluEntity";
import DilekceForm from "@/components/forms/DilekceForm.vue";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {ProgramPaths} from "@/enum/ProgramPaths";
import IsList from "@/components/lists/IsList.vue";
import KapakHesabi from "@/components/infos/KapakHesabi.vue";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
type FaizDataType = {
  hesaplanabilir: boolean;
  data: TakipEntity | { message: string };
};
@Component({
  components: {
    KapakHesabi,
    IsList,
    DilekceForm,
    DokumanList,
    KapakHesabiInfo,
    AlacakList,
    AlacakliCardList,
    BorcluCardList,
    TakipForm,
    FormDialog,
    ItemArrayManager,
    KiraBilgileriList,
    TakipYoluList,
    IlamBilgileriList,
    TakipEkAlanList,
    TakipEkAlan,
  },
})
export default class TakipEkleView extends Vue {
  @Ref('isList') readonly isListesi!: IsList;

  isIkili: boolean = false;
  item: TakipEntity = new TakipEntity();
  faizData: FaizDataType = { hesaplanabilir: false, data: { message: '' } };
  kiraKontrati: Array<AlacakEntity> = [];
  takipTipiRehinMiFlag = false;
  borclu !: BorcluEntity;
  fab = false;
  show1 = false;
  show2 = false;
  hover = false;
  isErrorShow = false;
  faizLoading = true;

  mounted() {
    let activeProcess = {id: this.$route.params.id, key: 'takip-ac', isRoot: true};
    this.$store.commit('setActiveProcess', activeProcess);
    this.load();
  }

  canliDestek() {
    window.open("https://livesupport.zirve-bilgisayar.com/canlidestek/chat.php", "_blank");
  }

  loadKiraKontrati(data: Array<AlacakEntity>) {
    this.kiraKontrati = data;
  }

  load() {
    if (this.$route.params.id) {
      this.faizLoading = true;
      this.$http.get('/api/v1/takip/' + this.$route.params.id).then((response) => {
        this.item = response.data;
        this.$nextTick(() => (this.isListesi.search()));
        this.takipTipiRehinMi(this.item);
        if (this.item.talepler[1] != null && this.item.talepler[1].id != null) {
          this.isIkili = true;
        }
        this.$http.post('/api/v1/takip/' + this.$route.params.id + '/faiz').then((faizResponse: any) => {
          this.faizData = faizResponse;
        }).finally(() => {
          this.faizLoading = false;
        });
      });
    }
    this.$emit('load');
  }

  takipTipiRehinMi(item: TakipEntity) {
    if (item) {
      item.talepler.forEach((talepler: TakipTalebiEntity) => {
        if (talepler.takip_tipi.rehin_mi) {
          this.takipTipiRehinMiFlag = true;
        }
      });
    }
  }

  takipAtla(sonrakiMi: boolean) {
    let filteredTakip = localStorage.getItem('filteredTakip');
    if (filteredTakip != null) {
      let filtreliTakipler = JSON.parse(filteredTakip);
      if (sonrakiMi && filtreliTakipler.index != filtreliTakipler.data.length - 1) {
        filtreliTakipler.index++;
        localStorage.setItem('filteredTakip', JSON.stringify(filtreliTakipler));
        let takipId = filtreliTakipler.data[filtreliTakipler.index];
        this.$http.get('/api/v1/takip/' + takipId + '/takip-durumu').then((response) => {
          if (response.data != TakipDurumu.acilmamis)
            this.$router.push(ProgramPaths.icrapro + '/takip/' + takipId);
          else
            this.$router.push(ProgramPaths.icrapro + '/takip-ekle/' + takipId);
        })
      } else if (!sonrakiMi && filtreliTakipler.index != 0) {
        filtreliTakipler.index--;
        localStorage.setItem('filteredTakip', JSON.stringify(filtreliTakipler));
        let takipId = filtreliTakipler.data[filtreliTakipler.index];
        this.$http.get('/api/v1/takip/' + takipId + '/takip-durumu').then((response) => {
          if (response.data != TakipDurumu.acilmamis)
            this.$router.push(ProgramPaths.icrapro + '/takip/' + takipId);
          else
            this.$router.push(ProgramPaths.icrapro + '/takip-ekle/' + takipId);
        })
      }
    }
  }
}
